import React from "react"

const SectionImage = ({ url, positionY }) => (
  <div
    style={{
      backgroundImage: `url(${url})`,
      backgroundPositionY: `${positionY}%`,
    }}
    className="HeaderImageContainer"
  >
    <div className="GradientOverlay"></div>
  </div>
)

const SectionTitle = ({ title }) => (
  <div
    style={{
      position: "absolute",
      top: 0,
      bottom: 0,
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    }}
  >
    <h1>{title}</h1>
  </div>
)

const SectionBody = ({ body }) => <div className="body">{body}</div>

const SectionHeader = ({ title, backgroundImageUrl, backgroundImagePosY }) => (
  <div className="SectionHeader">
    <SectionImage url={backgroundImageUrl} positionY={backgroundImagePosY} />
    <SectionTitle title={title} />
  </div>
)

export default ({
  id,
  title,
  backgroundImageUrl,
  backgroundImagePosY,
  body,
}) => {
  return (
    <div id={id} className="PageSection">
      <SectionHeader
        title={title}
        backgroundImageUrl={backgroundImageUrl}
        backgroundImagePosY={backgroundImagePosY}
      />
      <SectionBody body={body} />
    </div>
  )
}
