import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import PageSection from "../PageSection"

import "./AboutMe.css"

const imagesQuery = graphql`
  query {
    sectionImage: file(relativePath: { eq: "fod.jpg" }) {
      publicURL
    }
    locationImage: file(relativePath: { eq: "sortkasse.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 270) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    myBackgroundImage: file(relativePath: { eq: "mette270.png" }) {
      childImageSharp {
        fluid(maxWidth: 270) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    aboutMeImage: file(relativePath: { eq: "MetteNytLokale.png" }) {
      childImageSharp {
        fluid(maxWidth: 270) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const InfoBox = ({ fluid, title, text }) => {
  return (
    <div className="InfoBoxContainer">
      <h2>{title}</h2>
      <p>{text}</p>
      <Img
        style={{ marginTop: "auto", borderTop: "1px solid #dedede" }}
        fluid={fluid}
      />
    </div>
  )
}

export default () => {
  return (
    <StaticQuery
      query={imagesQuery}
      render={(data) => (
        <PageSection
          id="ommig"
          title="Om mig"
          backgroundImageUrl={data.sectionImage.publicURL}
          backgroundImagePosY={20}
          body={
            <div className="AboutMeBodyContainer">
              <h1
                style={{
                  fontFamily: "'Great Vibes', cursive",
                  fontWeight: "100",
                  fontSize: "1.6em",
                  color: "#777777",
                }}
              >
                Mette Christensen
              </h1>
              <div className="InfoContainer">
                <InfoBox
                  text={`Mit navn er Mette Christensen. Jeg er udlært og kan praktisere som lægeeksamineret fodplejer. Ydermere er jeg også uddannet zoneterapeut oktober 2022.`}
                  title="Lidt om mig"
                  fluid={data.aboutMeImage.childImageSharp.fluid}
                />
                <InfoBox
                  text="Jeg blev uddannet social og sundhedshjælper i 1998, for derefter at stifte Mettes Hjemmeservice i 2001, som i dag beskæftiger 5 medarbejdere."
                  title="Min baggrund"
                  fluid={data.myBackgroundImage.childImageSharp.fluid}
                />
                <InfoBox
                  text="Jeg har hjem samt klinik i Rantzausminde på Sydfyn. Der kører bus lige til døren, men kører også gerne ud."
                  title="Placering"
                  fluid={data.locationImage.childImageSharp.fluid}
                />
              </div>
            </div>
          }
        />
      )}
    />
  )
}
