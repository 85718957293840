import React from "react"
import { StaticQuery, graphql } from "gatsby"
import PageSection from "../PageSection"

import "./Services.css"

const imagesQuery = graphql`
  query {
    sectionImage: file(relativePath: { eq: "blueskies.jpg" }) {
      publicURL
    }
  }
`

const Service = ({ title, time, text }) => (
  <div className="ServiceRow">
    <div className="top">
      <h3>{title}</h3>
    </div>
    <p>{text}</p>
  </div>
)

export default () => {
  return (
    <StaticQuery
      query={imagesQuery}
      render={(data) => (
        <PageSection
          id="behandlinger"
          title="Behandlinger"
          backgroundImageUrl={data.sectionImage.publicURL}
          backgroundImagePosY={90}
          body={
            <div className="ServicesBodyContainer">
              <Service
                title="Fodbehandling"
                time={50}
                text="Fodbad, beskæring af hård hud, ligtorne, klipning af negle, rensning, 
          slipning af negle, behandling af nedgroede fortykkede negle. Afsluttes
          med negleolie og lettere fodmassage samt creme."
              />
              <Service
                title="Luksus fodbehandling"
                time={90}
                text="Fodbad, beskæring af hård hud, ligtorne, klipning af negle, rensning,
          slipning af negle, behandling af nedgroede fortykkede negle.
          Paraffin behandling af fødder, beroliger kroniske ledsmerter og lindrer
          muskelkramper samt forbedrer blodgennemstrømning i fødderne.
          Afsluttes med negleolie og lettere fodmassage samt creme."
              />
              <Service title="Fodbehandling med gel lak" time={90} text="" />
              <Service
                title="Vortebehandling"
                time={25}
                text="Vorten renses, beskæres og behandles med Verrutop.
          Behandlingen kræver 3-4 behandlinger med en uges mellemrum."
              />
              <Service title="Kører til dig efter behov" price={400} text="" />
            </div>
          }
        />
      )}
    />
  )
}
