import React from "react"
import Scrollspy from "react-scrollspy"
import { StaticQuery, graphql } from "gatsby"
import NavBar from "../components/NavBar"
import SEO from "../components/seo"
import AboutMe from "../components/sections/AboutMe"
import Services from "../components/sections/Services"
import Booking from "../components/sections/Booking"
import smoothscroll from "smoothscroll-polyfill"

import "../components/reset.css"
import "../components/layout.css"
import "../components/PageSection.css"

const certificate = graphql`
  query {
    certificate: file(name: { eq: "mette_certifikat" }) {
      publicURL
    }
  }
`

if (typeof window !== `undefined`) {
  // window.__forceSmoothScrollPolyfill__ = true
  smoothscroll.polyfill()
}

function scrollToSection(id) {
  document.querySelector(id).scrollIntoView({ behavior: "smooth" })
}

const IndexPage = () => {
  return (
    <div className="PageContainer">
      <SEO
        title="Mettes Fodpleje v. Mette Christensen"
        keywords={[`mette`, `fodpleje`, `fodterapi`, `fødder`]}
      />
      <NavBar href="#kontakt">
        <Scrollspy
          items={["ommig", "behandlinger", "kontakt"]}
          currentClassName="active"
          onUpdate={e =>
            window.history.pushState(null, null, e ? `#${e.id}` : "")
          }
        >
          <li className="MenuItem">
            <span onClick={() => scrollToSection("#ommig")}>Om mig</span>
          </li>
          <li className="MenuItem">
            <span onClick={() => scrollToSection("#behandlinger")}>
              Behandlinger
            </span>
          </li>
          <li className="MenuItem">
            <span onClick={() => scrollToSection("#kontakt")}>Kontakt</span>
          </li>
          <StaticQuery
            query={certificate}
            render={data => (
              <li className="MenuItem">
                <a
                  href={data.certificate.publicURL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>Certifikat</span>
                </a>
              </li>
            )}
          />
        </Scrollspy>
      </NavBar>
      <div className="SectionsContainer">
        <AboutMe />
        <Services />
        <Booking />
      </div>
    </div>
  )
}

export default IndexPage
