import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import "./NavBar.css"

const profileImageQuery = graphql`
  query {
    profileImage: file(relativePath: { eq: "profilbillede2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const ProfileImage = ({ fluid }) => (
  <div className="NavBarProfileImage">
    <Img fluid={fluid} />
  </div>
)

const Nav = ({ children }) => {
  return (
    <nav className="Nav">
      <ul>{children}</ul>
    </nav>
  )
}

const CTA = ({ href }) => (
  <div className="CtaContainer">
    <a href={href} className="Cta">
      Bestil tid her
    </a>
  </div>
)

export default ({ children, href }) => (
  <div className="NavBar">
    <div className="NavBarInner">
      <div className="top">
        <StaticQuery
          query={profileImageQuery}
          render={data => (
            <ProfileImage fluid={data.profileImage.childImageSharp.fluid} />
          )}
        />
        <h1>Mettes Fodpleje</h1>
        <Nav>{children}</Nav>
      </div>
      <div className="bottom">
        <CTA href={href} />
        <p>eller ring på tlf: 29 61 61 71</p>
      </div>
    </div>
  </div>
)
