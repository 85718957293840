import React from "react"
import { StaticQuery, graphql } from "gatsby"
import PageSection from "../PageSection"

import "./Booking.css"

const imagesQuery = graphql`
  query {
    sectionImage: file(relativePath: { eq: "blueskies.jpg" }) {
      publicURL
    }
  }
`

const Footer = props => (
  <div className="BookingFooter">
    <h1>Mettes Fodpleje</h1>
    <p>Rantzausmindevej 33</p>
    <p>5700 Svendborg</p>
    <p>Tlf: 29 61 61 71</p>
    <p>Mail: mette.nis@nielsen.mail.dk</p>
  </div>
)

export default () => {
  return (
    <StaticQuery
      query={imagesQuery}
      render={data => (
        <PageSection
          id="kontakt"
          title="Bestil tid"
          backgroundImageUrl={data.sectionImage.publicURL}
          backgroundImagePosY={90}
          body={
            <div className="BookingBodyContainer">
              <iframe
                title="Bestil tid"
                className="frame"
                frameBorder="0"
                height="1300"
                width="100%"
                src="https://mettesfodpleje.klikbook.dk/"
              />
              <Footer />
            </div>
          }
        />
      )}
    />
  )
}
